import React, { useState, useEffect, useRef } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Import utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import components
import TestimonialCard from "../../molecules/TestimonialCard"

// Import assets
import DepthRoundElement from "../../../assets/images/illustrations/depth-round-element.svg"

const AmanMalik = "aman-malik.png"
const SagarPatel = "sagar-patel.png"

const TestimonialSection = styled.div`
  display: block;
  width: 100%;
  position: relative;

  background-image: url(${DepthRoundElement});
  background-position: left 25% bottom -29rem;
  background-size: 58rem auto;
  background-repeat: no-repeat;

  ${xl} {
    background-size: 40rem auto;
    background-position: left 25% bottom -20rem;
  }

  ${lg} {
    background-size: 32rem auto;
    background-position: left 25% bottom -16rem;
  }
`

const TestimonialContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: column;
  padding: 7rem 7rem;
  justify-content: space-between;
  align-items: center;

  ${xl} {
    padding: 5rem 4rem;
  }

  ${sm} {
    padding: 3rem 2rem;
  }
`

const SectionHeading = styled.h2`
  font-weight: 700;
  line-height: 1.25;
  text-align: center;

  ${systemCss({
    fontSize: ["h3", "h3", "h2"],
    fontFamily: "primary",
    color: "purple"
  })}
`

const SectionSubheading = styled.h4`
  text-align: center;
  font-weight: 400;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  margin-top: 2rem;
  max-width: 90%;

  ${systemCss({
    color: "dark",
    fontSize: ["p", "h5", "h5", "h4"],
    fontFamily: "primary"
  })}

  ${sm} {
    margin-top: 1rem;
  }
`

const TestimonialCarouselWrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 8rem;

  ${xl} {
    margin-top: 6rem;
  }

  ${sm} {
    margin-top: 3rem;
  }
`

const TestimonialCarouselContainer = styled.div`
  margin: -2.5rem -2.5rem;
  overflow: hidden;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 2rem;
    background: linear-gradient(90deg, #fff, transparent);
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 2rem;
    background: linear-gradient(90deg, transparent, #fff);
    z-index: 1;
  }

  ${sm} {
    margin: -2rem -2rem;
  }
`

const TestimonialCarousel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  transition: transform 500ms ease 0s;
`

const TestimonialCardWrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  width: 50%;
  padding: 2.5rem;

  ${md} {
    flex: 0 0 100%;
    width: 100%;
  }

  ${sm} {
    padding: 2.5rem 2rem;
  }
`

const CarouselNavigator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4rem 0px 1rem;
  z-index: 1;

  @media (max-width: 480px) {
    margin-top: 1.6rem;
  }
`

const NavigatorDots = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const NavigatorDot = styled.li`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 100%;
  background-color: ${props => (props.isActive ? "#7c3380" : "#c4c4c4")};
  overflow: hidden;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 1rem;
  }

  @media (max-width: 480px) {
    height: 0.8rem;
    width: 0.8rem;

    &:not(:last-of-type) {
      margin-right: 0.6rem;
    }
  }
`

// Static content
const testimonials = [
  {
    name: "Pushpender Malik",
    image: AmanMalik,
    company: "Coinklap",
    note:
      "Folks at Tenxgeeks are skilled and motivated. Did a commendable job at building our dashboard. Would I recommend them? Definitely."
  },
  {
    name: "Sagar Patel",
    image: SagarPatel,
    company: "OceanX Studios",
    note:
      "Impressed by the on-time delivery and the support from the team. They helped us a lot in improve UI/UX for the overall product."
  }
]

export default props => {
  const [totalTestimonials, setTotalTestimonials] = useState(0)
  const [testimonialPerScroll, setTestimonialPerScroll] = useState(0)
  const [activeSlide, setActiveSlide] = useState(1)
  const [totalDots, setTotalDots] = useState(null)

  const testimonialScrollRef = useRef(null)

  const handleWindowResize = () => {
    if (window.innerWidth < 767.98 && testimonialPerScroll !== 1) {
      setTestimonialPerScroll(1)
    } else if (window.innerWidth >= 767.98 && testimonialPerScroll !== 2) {
      setTestimonialPerScroll(2)
    }
  }

  // Handle effects for window resize
  useEffect(() => {
    handleWindowResize()
    if (window) {
      window.addEventListener("resize", handleWindowResize)
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  // Handle effects for totals testimonials
  useEffect(() => {
    if (Array.isArray(testimonials)) {
      setTotalTestimonials(testimonials.length)
      handleWindowResize()
    }
  }, [])

  // Handle effects for totals dots
  useEffect(() => {
    if (testimonialPerScroll) {
      const totalDots = Math.ceil(totalTestimonials / testimonialPerScroll)
      setTotalDots(totalDots)

      // Reset all of the old aspects
      setActiveSlide(1)
      if (testimonialScrollRef)
        testimonialScrollRef.current.style.transform = "translateX(0%)"
    }
  }, [testimonialPerScroll])

  // Handle testimonial scroll
  const handleTestimonialScroll = e => {
    let scrollFrame =
      (e.target && e.target.dataset && e.target.dataset.key) || null

    scrollFrame = parseInt(scrollFrame)
    setActiveSlide(scrollFrame)
    if (testimonialScrollRef && scrollFrame) {
      const totalPxToScroll = (scrollFrame - 1) * -100
      testimonialScrollRef.current.style.transform = `translateX(${totalPxToScroll}%)`
    }
  }

  return (
    <TestimonialSection>
      <TestimonialContainer>
        <SectionHeading> What Our Clients Have to Say </SectionHeading>
        <SectionSubheading>
          It's great to get a pat on your back for a job well done. See what our
          clients have to say about us.
        </SectionSubheading>

        {/* Testimonial carousel */}
        <TestimonialCarouselWrapper>
          <TestimonialCarouselContainer>
            <TestimonialCarousel ref={testimonialScrollRef}>
              {testimonials.length &&
                testimonials.map((testimonial, index) => {
                  const { name, image, company, note } = testimonial

                  return (
                    <TestimonialCardWrapper key={index}>
                      <TestimonialCard
                        key={index}
                        {...{
                          name,
                          avatarImageUrl: image,
                          company,
                          note,
                          isVisible: true
                        }}
                      />
                    </TestimonialCardWrapper>
                  )
                })}
            </TestimonialCarousel>
          </TestimonialCarouselContainer>
        </TestimonialCarouselWrapper>

        {/* Testimonial carousel navigator */}
        <CarouselNavigator>
          <NavigatorDots>
            {totalDots
              ? Array.from(Array(totalDots).keys()).map(i => {
                  return (
                    <NavigatorDot
                      key={i}
                      isActive={i + 1 === activeSlide}
                      data-key={i + 1}
                      onClick={handleTestimonialScroll}
                    />
                  )
                })
              : null}
          </NavigatorDots>
        </CarouselNavigator>
      </TestimonialContainer>
    </TestimonialSection>
  )
}
