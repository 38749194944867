import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import withTheme from "@styled-system/css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const TestimonialCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 1.6rem;
  padding: 3rem;
  box-shadow: 0px 0px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 100%;

  ${xl} {
    flex-direction: column;
  }

  ${sm} {
    padding: 1.6rem;
  }
`

const CardXL = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  ${xl} {
    align-items: center;
  }
`

const CardAvatar = styled.div`
  display: flex;
`

const CardAvatarImage = styled(Img)`
  display: block;
  height: 14rem;
  width: 14rem;
  border-radius: 100%;
  overflow: hidden;
  background-color: #e5e5e5;

  ${xl} {
    height: 10rem;
    width: 10rem;
  }

  ${lg} {
    height: 8rem;
    width: 8rem;
  }
`

const CardContent = styled.div`
  margin-left: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const ClientName = styled.h5`
  margin: 0px;
  padding: 0px;
  font-weight: 700;

  ${withTheme({
    fontFamily: "primary",
    color: "dark",
    fontSize: "h5"
  })}

  ${lg} {
    ${withTheme({
      fontSize: "p"
    })}
  }
`

const ClientCompany = styled.span`
  margin-top: 1rem;
  font-weight: 700;
  ${withTheme({
    fontFamily: "primary",
    color: "dark",
    fontSize: "link"
  })}

  ${lg} {
    margin-top: 0.6rem;
    ${withTheme({
      fontSize: "1"
    })}
  }
`
const ClientTestimonial = styled.p`
  margin-top: 0.8rem;
  font-weight: 400;
  line-height: 1.35;

  ${withTheme({
    fontFamily: "primary",
    color: "dark",
    fontSize: "p"
  })}

  ${xl} {
    display: none;
  }
`
const TestimonialContent = styled.p`
  display: none;
  ${withTheme({
    fontFamily: "primary",
    color: "dark",
    fontSize: "p"
  })}

  ${xl} {
    margin-top: 1.25rem;
    display: block;
  }

  ${lg} {
    margin-top: 1rem;
    font-size: 1.6rem;
  }
`

const clientImageQuery = graphql`
  query {
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 140, quality: 100) {
            ...GatsbyImageSharpFluid
            originalName
          }
        }
      }
    }
  }
`

export default ({ avatarImageUrl, name, company, note }) => {
  const imageData = useStaticQuery(clientImageQuery)

  const image = () => {
    const image = imageData.allImageSharp.edges.find(edge => {
      return edge.node.fluid.originalName === avatarImageUrl
    })
    if (!image) {
      return null
    }
    return image.node.fluid
  }
  return (
    <TestimonialCard>
      <CardXL>
        <CardAvatar>
          <CardAvatarImage fluid={image()} alt={name || "Client Avatar"} />
        </CardAvatar>
        <CardContent>
          <ClientName>{name}</ClientName>
          <ClientCompany>{company}</ClientCompany>
          <ClientTestimonial>{note}</ClientTestimonial>
        </CardContent>
      </CardXL>
      <TestimonialContent>{note}</TestimonialContent>
    </TestimonialCard>
  )
}
