import React from "react"

// Import components
import SEO from "../components/global/seo"
import GlobalStyles from "../components/global/GlobalStyles"
import Header from "../components/organisms/Header"
import HeroSection from "../components/organisms/HeroSection"
import ServiceSection from "../components/organisms/ServiceSection"
import AboutSection from "../components/organisms/AboutSection"
import CTASection from "../components/organisms/CTASection"
import Footer from "../components/organisms/Footer"
import TestimonialSection from "../components/organisms/TestimonialSection"
import ShowcaseSection from "../components/organisms/ShowcaseSection"

const LandingPage = () => {
  return (
    <>
      <GlobalStyles />
      <SEO title="Top Design and Development Company" />
      <Header />
      <HeroSection />
      <ServiceSection />
      <AboutSection />
      <ShowcaseSection />
      <TestimonialSection />
      <CTASection />
      <Footer />
    </>
  )
}

export default LandingPage
