import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import withTheme from "@styled-system/css"

// Import components
import ShowcaseShot from "../../molecules/ShowcaseShot"

// Import assets
import DepthInfiniteElement2 from "../../../assets/images/illustrations/depth-infinity-element2.svg"
import DepthInfiniteElement4 from "../../../assets/images/illustrations/depth-infinity-element4.svg"

// Coinklap assets
import CoinklapLogo from "../../../assets/images/brands/coinklap-grayscale.svg"

// Groot Gamers assets
import GrootGamersLogo from "../../../assets/images/brands/groot-gaming-grayscale.svg"

// Fight CoronaShot assets
import TenxgeeksLogo from "../../../assets/images/brand-logo/tenxgeeks-grayscale-logo.svg"

const CoinklapShot = "coinklap-shot.png"
const GrootGamersShot = "groot-gamer-shot.png"
const FightCoronaShot = "corona-virus-shot.png"
const ApphidShot = "apphid-shot.png"

import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const ShowcaseSection = styled.div`
  display: block;
  width: 100%;
  position: relative;

  background-image: url(${DepthInfiniteElement2}), url(${DepthInfiniteElement4});
  background-position: left -6rem top 15rem, right -3.5rem bottom 0;
  background-size: 26rem auto, 26rem auto;
  background-repeat: no-repeat, no-repeat;

  ${lg} {
    background-position: left -4rem top 15rem, right -3rem bottom 0;
    background-size: 20rem auto, 20rem auto;
  }
  ${sm} {
    background-position: left -3rem top 17.5rem, right -2.5rem bottom 0;
    background-size: 14rem auto, 14rem auto;
  }
`

const ShowcaseContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: column;
  padding: 7rem 7rem;
  justify-content: space-between;
  align-items: center;

  ${xl} {
    padding: 5rem 4rem;
  }

  ${sm} {
    padding: 3rem 2rem;
  }
`

const SectionHeading = styled.h2`
  text-align: center;
  font-weight: 700;
  line-height: 1.25;

  ${withTheme({
    fontSize: ["h3", "h2"],
    fontFamily: "primary",
    color: "purple"
  })}
`

const SectionSubheading = styled.h4`
  text-align: center;
  font-weight: 400;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  margin-top: 2rem;

  ${withTheme({
    color: "dark",
    fontSize: ["p", "h5", "h5", "h4"],
    fontFamily: "primary"
  })}
`

const ShotBox = styled.div`
  display: block;
  width: 100%;
  margin-top: 8rem;

  ${lg} {
    margin-top: 6rem;
    max-width: 70rem;
  }

  ${md} {
    max-width: 100%;
  }

  ${sm} {
    margin-top: 2.4rem;
  }
`

const ShotContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 16rem;
  grid-gap: 4rem;

  ${xl} {
    grid-auto-rows: 13rem;
  }

  ${lg} {
    grid-template-columns: 1fr;
    grid-auto-rows: 18rem;
  }

  ${md} {
    grid-auto-rows: 16rem;
  }

  ${sm} {
    grid-auto-rows: 11rem;
    grid-gap: 2.5rem;
  }
`

// Static content
const showcaseShots = [
  {
    orientation: "LANDSCAPE",
    shot: CoinklapShot,
    brandLogo: CoinklapLogo,
    brandName: "Coinklap",
    service: "Frontend Engineering"
  },
  {
    orientation: "PORTRAIT",
    shot: GrootGamersShot,
    brandLogo: GrootGamersLogo,
    brandName: "Groot Gamers",
    service: "UI/UX, Brand Identity"
  },
  {
    orientation: "PORTRAIT",
    shot: ApphidShot,
    brandLogo: TenxgeeksLogo,
    brandName: "Apphid",
    service: "UI/UX, Brand Identity"
  },
  {
    orientation: "LANDSCAPE",
    shot: FightCoronaShot,
    brandLogo: TenxgeeksLogo,
    brandName: "Fight Corona",
    service: "UI/UX, Web Development"
  }
]

export default props => {
  return (
    <ShowcaseSection>
      <ShowcaseContainer>
        <SectionHeading>Projects We Have Worked On</SectionHeading>
        <SectionSubheading>Work we are proud of</SectionSubheading>
        <ShotBox>
          <ShotContainer>
            {Array.isArray(showcaseShots) && showcaseShots.length
              ? showcaseShots.map((showcaseShot, index) => {
                  const {
                    orientation,
                    brandLogo,
                    brandName,
                    service,
                    shot
                  } = showcaseShot
                  return (
                    <ShowcaseShot
                      key={index}
                      {...{
                        orientation,
                        brandLogo,
                        brandName,
                        service,
                        shot
                      }}
                    />
                  )
                })
              : null}
          </ShotContainer>
        </ShotBox>
      </ShowcaseContainer>
    </ShowcaseSection>
  )
}
