import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import withTheme from "@styled-system/css"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

// Import assets
import BrandLogoGrayscale from "../../../assets/images/brand-logo/tenxgeeks-grayscale-logo.svg"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const ShowcaseShot = styled(BackgroundImage)`
  display: block;
  border-radius: 2rem;
  box-shadow: 0px 0px 40px 0 rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  grid-column: span 1;
  grid-row: ${props =>
    props.orientation === "PORTRAIT" ? "span 3" : "span 2"};
  border: none;
  position: relative;
  overflow: hidden !important;

  ${sm} {
    grid-row: ${props =>
      props.orientation === "PORTRAIT" ? "span 4" : "span 2"};
  }
`

const ShotDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 2.5rem;

  ${sm} {
    padding: 1.75rem;
  }
`

const BrandLogo = styled.div`
  display: block;
  height: 5.25rem;
  width: auto;

  & > img {
    display: block;
    height: 100%;
    width: auto;
  }

  ${sm} {
    height: 4rem;
  }
`

const WorkDescription = styled.div`
  margin-left: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${sm} {
    margin-left: 1.5rem;
  }
`

const ProjectName = styled.h5`
  font-weight: 700;

  ${withTheme({
    fontSize: "h5",
    color: "white",
    fontFamily: "primary"
  })}

  ${sm} {
    ${withTheme({
      fontSize: "p"
    })}
  }
`

const ServiceDescription = styled.p`
  margin-top: 0.6rem;
  font-weight: 400;

  ${withTheme({
    fontSize: "p",
    color: "white",
    fontFamily: "primary"
  })}

  ${lg} {
    margin-top: 0.4rem;
    ${withTheme({
      fontSize: "link"
    })}
  }

  ${sm} {
    ${withTheme({
      fontSize: "1"
    })}
  }
`

const bgImageQuery = graphql`
  query {
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid
            originalName
          }
        }
      }
    }
  }
`

export default ({ orientation, brandLogo, brandName, service, shot }) => {
  const imageData = useStaticQuery(bgImageQuery)

  const image = () => {
    const image = imageData.allImageSharp.edges.find(edge => {
      return edge.node.fluid.originalName === shot
    })
    if (!image) {
      return null
    }
    return [
      `linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 66%,
        rgba(0, 0, 0, 0.5) 100%
      )`,
      image.node.fluid
    ]
  }

  return (
    <ShowcaseShot orientation={orientation} fluid={image()}>
      <ShotDescription>
        <BrandLogo>
          <img src={brandLogo || BrandLogoGrayscale} alt="brand logo" />
        </BrandLogo>
        <WorkDescription>
          <ProjectName>{brandName}</ProjectName>
          <ServiceDescription>{service}</ServiceDescription>
        </WorkDescription>
      </ShotDescription>
    </ShowcaseShot>
  )
}
