import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"
import { Link } from "gatsby"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import assets
import AboutUsIllustration from "../../../assets/images/illustrations/tenxgeeks-about-us-illustration.svg"
import DepthInfinityElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"

const AboutSection = styled.div`
  display: block;
  width: 100%;

  background-image: url(${DepthInfinityElement1});
  background-position: right -10rem top 50%;
  background-size: 26rem auto;
  background-repeat: no-repeat;

  ${lg} {
    background-position: right -8rem top 87.5%;
    background-size: 20rem auto;
  }

  ${sm} {
    background-position: right -5rem top 70%;
    background-size: 12rem auto;
  }
`

const AboutContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: row;
  padding: 7rem 7rem;
  justify-content: space-between;
  align-items: center;

  ${xl} {
    padding: 5rem 4rem;
  }

  ${lg} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }

  ${sm} {
    padding: 3rem 2rem;
  }
`

const AboutIllustration = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  max-width: 50%;
  display: flex;
  padding: 0px 1rem;
  align-items: center;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  ${lg} {
    max-width: 80%;
    align-items: center;
    justify-content: center;
    align-self: center;
  }

  ${sm} {
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
`

const AboutContent = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0px 1rem;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${lg} {
    max-width: 80%;
    padding: 0px 1rem;
    margin-top: 4rem;
  }

  ${sm} {
    max-width: 95%;
    margin-top: 2.5rem;
  }
`

const SectionHeading = styled.h2`
  font-weight: 700;
  line-height: 1.25;
  padding: 0px;
  margin: 0px;

  ${systemCss({
    color: "purple",
    fontSize: ["h3", "h3", "h2"],
    fontFamily: "primary"
  })}
`

const SectionSubheading = styled.h4`
  font-weight: 400;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  margin-top: 2rem;

  ${systemCss({
    color: "dark",
    fontSize: ["p", "p", "h5", "h4"],
    fontFamily: "primary"
  })}
`

const CTALink = styled(Link)`
  margin-top: 1.25rem;
  font-weight: 700;
  outline: none;
  background: transparent;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  text-decoration: none;

  ${systemCss({
    fontSize: ["base", "link"],
    color: "red"
  })}

  &:hover {
    ${systemCss({
      color: "red"
    })}
  }
`

export default props => {
  return (
    <AboutSection>
      <AboutContainer>
        <AboutIllustration>
          <img src={AboutUsIllustration} alt="about us illustration" />
        </AboutIllustration>
        <AboutContent>
          <SectionHeading>About Us</SectionHeading>
          <SectionSubheading>
            A powerhouse team of geeks, Which build Engaging Digital Experiences
            with the combined power of design and development.
          </SectionSubheading>
          <CTALink to="/about-us">Our Team</CTALink>
        </AboutContent>
      </AboutContainer>
    </AboutSection>
  )
}
