import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const ServiceCard = styled.div`
  height: 100%;
  width: 100%;
  padding: 3rem 2.5rem;
  border-radius: 1.6rem;
  background: #ffffff;

  /* Drop Shawdow */
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
`

const ServiceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const ServiceCardImage = styled.img`
  max-height: 16rem;
  width: auto;

  ${sm} {
    max-height: 10rem;
  }
`

const ServiceCardHeading = styled.h5`
  margin-top: 2.25rem;
  font-weight: 700;
  line-height: 1.25;

  ${systemCss({
    fontSize: "h5",
    color: "dark"
  })}

  ${sm} {
    margin-top: 2rem;
  }
`

const ServiceCardContent = styled.p`
  margin-top: 1.6rem;
  line-height: 1.25;
  text-align: center;

  ${systemCss({
    color: "dark",
    fontSize: ["link", "p"]
  })}

  ${sm} {
    margin-top: 1rem;
  }
`

export default props => {
  const { illustration, heading, description } = props

  return (
    <ServiceCard>
      <ServiceCardContainer>
        <ServiceCardImage src={illustration} alt="service illustration" />
        <ServiceCardHeading>{heading}</ServiceCardHeading>
        <ServiceCardContent>{description}</ServiceCardContent>
      </ServiceCardContainer>
    </ServiceCard>
  )
}
