import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Import components
import ServiceCard from "../../molecules/ServiceCard"

// Import assets
import DepthInfiniteElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"
import DepthInfiniteElement2 from "../../../assets/images/illustrations/depth-infinity-element2.svg"
import DepthInfiniteElement3 from "../../../assets/images/illustrations/depth-infinity-element3.svg"
import UiUxIllustration from "../../../assets/images/illustrations/tenxgeeks-ui-ux-illustration.svg"
import BrandingIllustration from "../../../assets/images/illustrations/tenxgeeks-branding-illustration.svg"
import NativeIllustration from "../../../assets/images/illustrations/tenxgeeks-native-solutions-illustration.svg"
import WebIllustration from "../../../assets/images/illustrations/tenxgeeks-web-solutions-illustration.svg"
import SprintIllustration from "../../../assets/images/illustrations/tenxgeeks-sprint-illustration.svg"
import ChatBotIllustration from "../../../assets/images/illustrations/tenxgeeks-chatbot-illustration.svg"

import { sm, md, lg, xl } from "../../../utils/mediaQueries"

const ServiceSection = styled.div`
  display: block;
  width: 100%;
  position: relative;

  background-image: url(${DepthInfiniteElement2}), url(${DepthInfiniteElement1}),
    url(${DepthInfiniteElement3});
  background-position: right 10% top 15rem, right 12.5% bottom 20rem,
    left 7.5% top 60%;
  background-size: 14rem auto, 14rem auto, 12rem auto;
  background-repeat: no-repeat, no-repeat, no-repeat;

  ${sm} {
    background-position: right 5% top 10rem, right 100% bottom 50%,
      left 100% top 100%;
    background-size: 10rem auto, 6rem auto, 6rem auto;
  }
`

const ServiceContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: column;
  padding: 7rem 7rem;
  justify-content: space-between;
  align-items: center;

  ${xl} {
    padding: 5rem 4rem;
  }

  ${sm} {
    padding: 3rem 2rem;
  }
`

const SectionHeading = styled.h2`
  text-align: center;
  font-weight: 700;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["h3", "h3", "h2"],
    fontFamily: "primary",
    color: "purple"
  })}
`

const ServicesContainer = styled.div`
  width: 100%;
  display: grid;
  margin-top: 8rem;
  grid-gap: 6rem;
  grid-template-columns: repeat(auto-fill, minmax(31rem, 32rem));
  justify-content: center;

  ${lg} {
    margin-top: 5rem;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 32rem));
    grid-gap: 4.6rem;
  }

  ${md} {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 42rem));
  }

  ${sm} {
    margin-top: 3rem;
    grid-gap: 2.4rem;
  }
`

// Static content
const services = [
  {
    illustration: UiUxIllustration,
    heading: "UI/UX Design",
    description:
      "We deliver engaging and exceptional experiences for end-users with our UI/UX methodologies."
  },
  {
    illustration: BrandingIllustration,
    heading: "Branding",
    description:
      "A brand is much more than a logo or identity design, we help you create an emotional relationship between the consumer and your business."
  },
  {
    illustration: WebIllustration,
    heading: "Web Development",
    description:
      "We specialize in building user-friendly, feature-rich and secure web applications to scale your business using the latest technologies."
  },
  {
    illustration: NativeIllustration,
    heading: "Mobile Development",
    description:
      "We create intuitive, high quality, well-tested mobile-based applications that your users love and deserve."
  },
  {
    illustration: SprintIllustration,
    heading: "Design Sprints",
    description:
      "Answer critical business questions through design, prototyping and testing new ideas with users within a week workshop."
  },
  {
    illustration: ChatBotIllustration,
    heading: "Chatbots",
    description:
      "We build Chatbots to enhances the quality of responses to user queries through a conversational interface."
  }
]

export default props => {
  return (
    <ServiceSection>
      <ServiceContainer>
        <SectionHeading>Our Range of Services</SectionHeading>
        <ServicesContainer>
          {services.map((service, index) => {
            const { illustration, heading, description } = service

            return (
              <ServiceCard
                key={index}
                illustration={illustration}
                heading={heading}
                description={description}
              />
            )
          })}
        </ServicesContainer>
      </ServiceContainer>
    </ServiceSection>
  )
}
