import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import assets
import DepthInfinityElement2 from "../../../assets/images/illustrations/depth-infinity-element2.svg"
import HeroIllustrationImg from "../../../assets/images/illustrations/tenxgeeks-concept-illustration-2.svg"

// Import components
import { SecondaryButton } from "../../atoms/Button"

const HeroSection = styled.div`
  display: block;
  width: 100%;
  position: relative;
`

const HeroContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14rem 7rem 7rem;

  ${xl} {
    padding: 10rem 4rem 5rem;
  }

  ${lg} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }

  ${sm} {
    padding: 5rem 2rem 3rem;
  }
`

const HeroContent = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${lg} {
    max-width: 85%;
  }

  ${sm} {
    max-width: 100%;
  }
`

const HeroContentHeading = styled.h1`
  font-weight: 700;
  line-height: 1.2;

  ${systemCss({
    fontSize: ["h3", "h3", "h2", "h1"],
    color: "purple",
    fontFamily: "primary"
  })}
`

const HeroContentDescription = styled.h4`
  margin-top: 2rem;
  font-weight: 400;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["p", "p", "h5", "h4"],
    color: "dark",
    fontFamily: "primary"
  })}

  ${sm} {
    margin-top: 1rem;
  }
`

const CTAButton = styled(SecondaryButton)`
  margin-top: 3rem;

  ${sm} {
    margin-top: 1.6rem;

    ${systemCss({
      fontSize: "btn.sm",
      padding: "btn.sm",
      height: "btn.sm"
    })}
  }
`

const HeroIllustration = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  padding: 0px 1rem;
  align-items: center;

  & > img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  ${lg} {
    margin-top: 0rem;
    max-width: 80%;
    align-self: flex-end;
  }

  ${sm} {
    margin-top: 2.5rem;
    max-width: 100%;
    align-self: center;
  }
`

const DepthElement = styled.div`
  z-index: -1;
  position: absolute;
  bottom: 0rem;
  left: -10rem;
  height: 26rem;
  width: auto;
  transform: translateY(70%);

  & > img {
    display: block;
    height: 100%;
    width: auto;
  }

  ${lg} {
    height: 18rem;
    width: auto;
    bottom: 35%;
    left: -9rem;
  }

  ${sm} {
    height: 8rem;
    width: auto;
    bottom: 60%;
    left: auto;
    right: 2.5rem;
  }
`

export default props => {
  return (
    <HeroSection>
      <HeroContainer>
        <HeroContent>
          <HeroContentHeading>
            We Turn Your Ideas Into Reality
          </HeroContentHeading>
          <HeroContentDescription>
            We build scalable, engaging and budget friendly digital experiences.
          </HeroContentDescription>
          <CTAButton size="md" type="button">
            Explore Projects
          </CTAButton>
        </HeroContent>

        <HeroIllustration>
          <img src={HeroIllustrationImg} alt="hero illustration" />
        </HeroIllustration>
      </HeroContainer>
      <DepthElement>
        <img src={DepthInfinityElement2} alt="depth element" />
      </DepthElement>
    </HeroSection>
  )
}
